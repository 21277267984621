import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/analytics/_functions", // Replace with your API base URL
      applicationID: "905c285f-0358-48c8-8d52-b1f1e5a9c71b", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
  }, []);

  useEffect(() => {
    // redirect user to http://165.22.96.113/analytics/?token= + instance from url
    if (instance) {
      // send an request to POST https://certifiedcode.wixsite.com/analytics/_functions/settings with empty body with auth = instance
      fetch("https://certifiedcode.wixsite.com/analytics/_functions/settings", {
        method: "POST",
        headers: {
          Authorization: instance!,
        },
      });
      window.location.href = `https://manage.analytics.certifiedcode.us/?token=${instance}`;
    }
  }, []);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
